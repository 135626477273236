<template>
  <a href="https://www.linkedin.com/in/radu-gheorghe-798913166/"
     class="fa fa-linkedin"
     target="_blank"
     rel="noopener noreferrer"
  ></a>
  <a href="https://github.com/rdghe"
     class="fa fa-github"
     target="_blank"
     rel="noopener noreferrer"
  ></a>
  <a href="https://www.instagram.com/raduuugheorghe/"
     class="fa fa-instagram"
     target="_blank"
     rel="noopener noreferrer"
  ></a>
  <a href="https://www.snapchat.com/add/radugheorghe"
     class="fa fa-snapchat-ghost" target="_blank"
     rel="noopener noreferrer"
  ></a>
  <a href="mailto:radu98@gmail.com"
     class="fa fa-envelope"
  ></a>
  <a href="https://www.facebook.com/radoogheorghe/"
     class="fa fa-facebook"
     target="_blank"
     rel="noopener noreferrer"
  ></a>
</template>


<style>
.fa {
  padding: 2rem;
  font-size: 3rem;
  width: 2.5rem;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  color: white;
}

.fa:hover {
  opacity: 0.7;
}

</style>